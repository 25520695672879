// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-email-bestaetigen-js": () => import("./../../../src/pages/email-bestaetigen.js" /* webpackChunkName: "component---src-pages-email-bestaetigen-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intern-js": () => import("./../../../src/pages/intern.js" /* webpackChunkName: "component---src-pages-intern-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-passwort-erstellen-js": () => import("./../../../src/pages/passwort-erstellen.js" /* webpackChunkName: "component---src-pages-passwort-erstellen-js" */),
  "component---src-pages-passwort-vergessen-js": () => import("./../../../src/pages/passwort-vergessen.js" /* webpackChunkName: "component---src-pages-passwort-vergessen-js" */),
  "component---src-templates-standard-site-js": () => import("./../../../src/templates/standardSite.js" /* webpackChunkName: "component---src-templates-standard-site-js" */)
}

